import {createAsyncThunk, createSlice, current} from "@reduxjs/toolkit";
import {SyncClients} from "../../helper/sync";
import {getAllClientsOffline, getLastSyncDate, updateClientOffline} from "../../store/offlineDb";
import moment from "moment";
import {activateClientApi, deleteClientApi} from "../../api";

export const clientsSync = createAsyncThunk('clientsSync', async () => {
    const lastDate = await getLastSyncDate();
    let data;
    if (!lastDate || !lastDate?.date || moment(lastDate.date).isBefore(moment().subtract(1, "day"))) {
        data = SyncClients()
    } else {
        data = getAllClientsOffline();
    }
    return data;
})

export const forceClientSync = createAsyncThunk('forceClientSync', async () => {
    return SyncClients();
})

export const fetchAllClients = createAsyncThunk('fetchAllClients', async () => {
    return getAllClientsOffline();
})

export const deleteClient = createAsyncThunk('deleteClient', async (client) => {
    await deleteClientApi(client.uid);
    await updateClientOffline(client);
});

export const activateClient = createAsyncThunk('activateClient', async (client) => {
    await activateClientApi(client.uid);
    await updateClientOffline(client);
});


const initialState = {
    clients: [],
    loading: false,
    syncInProgress: false,
}

const slice = createSlice({
    name: 'clients',
    initialState,
    reducers: {},
    extraReducers: {
        [clientsSync.pending]: (state)=>{
            state.syncInProgress = true;
        },
        [clientsSync.fulfilled]: (state, {payload}) => {
            if (payload) {
                state.clients = payload;
            }
            state.syncInProgress = false;
        },
        [clientsSync.rejected]: (state)=>{
            state.syncInProgress = false;
        },
        [forceClientSync.pending]: (state) => {
            state.loading = true;
            state.syncInProgress = true;
        },
        [forceClientSync.fulfilled]: (state, {payload}) => {
            if (payload) {
                state.clients = payload;
            }
            state.loading = false;
            state.syncInProgress = false;
        },
        [forceClientSync.rejected]: (state) => {
            state.loading = false;
            state.syncInProgress = false;
        },
        [fetchAllClients.pending]: (state) => {
            state.loading = false;
        },
        [fetchAllClients.fulfilled]: (state, {payload}) => {
            state.clients = payload;
            state.loading = false;
        },
        [fetchAllClients.rejected]: (state) => {
            state.loading = false;
        },
        //deleteClient
        [deleteClient.pending]: (state) => {
            state.loading = false;
        },
        [deleteClient.fulfilled]: (state, {meta}) => {
            let arr = [...current(state).clients]
            let index = arr.findIndex(obj => obj.uid === meta.arg.uid)
            if (index !== -1) {
                arr[index] = {
                    ...arr[index],
                    disabled: true
                }
                state.clients = arr
            }
            state.loading = false;
        },
        [deleteClient.rejected]: (state) => {
            state.loading = false;
        },
        //activateClient
        [activateClient.pending]: (state) => {
            state.loading = false;
        },
        [activateClient.fulfilled]: (state, {meta}) => {
            let arr = [...current(state).clients]
            let index = arr.findIndex(obj => obj.uid === meta.arg.uid)
            if (index !== -1) {
                arr[index] = {
                    ...arr[index],
                    disabled: false
                }
                state.clients = arr
            }
            state.loading = false;
        },
        [activateClient.rejected]: (state) => {
            state.loading = false;
        },
    }
});
export default slice.reducer;
